@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.accordion {
  background: transparent;
  scroll-margin: fn.spacing(10);
  display: block;
  border-bottom: 1px solid var.$color-neutral-700;

  &:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:first-of-type) {
    padding-top: 32px;
  }
}

.accordion-summary {
  padding: 16px 0;
}

.accordion-summary-text {
  padding-right: 16px;
  margin: 0;
}

.accordion-detail {
  padding-bottom: 8px;
}

.hidden-content {
  display: none;
}
